import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../../components/layout'
import './contact.css'

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        //   tab41: false
          // Note: think carefully before initializing
          // state based on props!
          // someInitialValue: this.props.initialValue
        }
      }


render() {
return ( 
    <Layout>
    {/* <Header /> */}
    <div className="contact-wrapper robot-wrapper">
        <div className="card section">
            <h2>Get In Touch</h2>
            <div className="contact-letter">
            <p>
            We’d love to hear from you, robotic homebuilding is here. If you’re involved in homebuilding and want to be on the cutting edge of the industry it would be great to hear what you think. Drop us a note.
            </p>

            <div className="sig">
            Sincerely,<br />
                Jeff Williams, <br />
                Founder&CEO
            </div>

            <p>
        P.S. And yes, we know about Hadrian and 3D printed houses - great work!   This is happening now.
            </p>




            <div className="contact-info">
                <ul>
                    <li><a href="mailto:jwilliams@williamsrobotics.com">jwilliams@williamsrobotics.com</a></li>
                    <li><a href="tel:919-724-2869">919-724-2869</a></li>
                </ul>
            </div>

            </div>
    </div>
    </div>

    {/* <div className="robot-wrapper"></div> */}

    </Layout>
    );
  }
}

export default Contact

